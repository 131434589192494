<style lang="scss" scoped>
@keyframes load {
  from {
    background: #DEDEDE;
  }
  to {
    background: #EEEEEE;
  }
}
.loading {
  padding: 0.13rem 0.13rem;
  .loading-circle {
    width: .12rem;
    height: .12rem;
    border-radius: 100%;
    display: inline-block;
    margin-right: .1rem;
    background: #DEDEDE;
    animation: load 1.5s;
    animation-iteration-count: infinite;
    &:last-child {
      margin-right: 0;
    }
  }
  @for $i from 1 through 3 {
    .circle_#{$i} {
      animation-delay: (0.2s * $i);
    }
  }
}
</style>

<template>
  <div class="chat-robot-box">
    <div class="loading">
      <div class="loading-circle circle_1"></div>
      <div class="loading-circle circle_2"></div>
      <div class="loading-circle circle_3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BubbleLoading'
}
</script>
